define("euclidean-cracked/models/project", ["exports", "ember-data", "ember-concurrency"], function (_exports, _emberData, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    slug: _emberData.default.attr('string'),
    date: _emberData.default.attr('date'),
    creator: _emberData.default.belongsTo('user'),
    bpm: _emberData.default.attr('number', {
      defaultValue() {
        return 100;
      }

    }),
    tracks: _emberData.default.hasMany('track'),
    comments: _emberData.default.hasMany('comment'),
    activeUsers: _emberData.default.hasMany('user', {
      inverse: 'activeProject',

      defaultValue() {
        return [];
      }

    }),
    publicVisible: _emberData.default.attr('boolean'),
    publicEditable: _emberData.default.attr('boolean'),

    init() {
      this._super(...arguments);

      this.set('masterCompressorSelector', '#master-compressor');
    },

    saveTask: (0, _emberConcurrency.task)(function* () {
      yield this.save();
      yield (0, _emberConcurrency.timeout)(300);
    }).keepLatest(),

    async eachTrackAsync(asyncFn) {
      const tracks = await this.get('tracks');

      for (const track of tracks.toArray()) {
        await asyncFn(track);
      }
    },

    // TODO: move to project controller?
    async initializeTrackSamplers() {
      return this.eachTrackAsync(track => {
        track.set('stepIndex', 1);
        track.initializeSampler.perform();
      });
    },

    sequenceMatrix: Ember.computed('tracks.@each.sequence', {
      get() {
        const tracks = this.get('tracks');

        if (tracks.length) {
          const matrix = new Nexus.Matrix(tracks.length, 16);
          tracks.forEach((track, idx) => {
            matrix.set.row(idx, track.get('sequence'));
          });
          return matrix;
        }
      }

    })
  });

  _exports.default = _default;
});