define("euclidean-cracked/controllers/user/creator/project", ["exports", "euclidean-cracked/utils/clean", "ember-concurrency", "euclidean-cracked/utils/example-init-functions"], function (_exports, _clean, _emberConcurrency, _exampleInitFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    saveTrackTask: (0, _emberConcurrency.task)(function* (track) {
      try {
        yield track.save();
      } catch (e) {
        Ember.debug("error saving track (project task):  ".concat(e));
        track.rollbackAttributes();
      }
    }),
    activeTrack: Ember.computed('model.tracks.[]', {
      get() {
        return this.model.get('tracks').firstObject;
      }

    }),
    createTrack: (0, _emberConcurrency.task)(function* (project) {
      // every track must have 2 customFunction methods. 
      // ideally these would get created in a cloud function on track create, not in the client
      let onstepFunction = this.store.createRecord('customFunction', {
        projectCreatorUid: project.get('creator.uid')
      });
      let initFunction = this.store.createRecord('customFunction', {
        projectCreatorUid: project.get('creator.uid'),
        editorContent: _exampleInitFunctions.default[0].examples[0].code,
        functionPreCheck: _exampleInitFunctions.default[0].examples[0].code
      });
      yield initFunction.save();
      yield onstepFunction.save(); // TODO: instead of setting defaults here, just use
      // defaults on the model
      // or better yet, uses a post-create cloud function to setup default tracks, track custom controls

      let track = this.store.createRecord('track', {
        projectCreatorUid: project.get('creator.uid'),
        publicEditable: project.publicEditable,
        onstepFunction: onstepFunction,
        initFunction: initFunction
      }); //  shouldnt need to do this but prevents firebase errors

      track.set('onstepFunction', onstepFunction);
      track.set('initFunction', initFunction);
      project.get('tracks').addObject(track);
      yield track.save();
      yield project.save();
      return track;
    }),
    actions: {
      save(project) {
        let slug = (0, _clean.default)(project.get('title'));
        project.set('slug', slug);
        project.save().then(project => {
          this.transitionToRoute('user.creator.project', project.slug);
        });
      },

      // when a user clicks a track in the list,
      //  set footer controls to that track
      selectActiveTrack(track) {
        // instance of track model
        this.set('activeTrack', track);
      },

      async deleteTrack(track) {
        // TODO: delete onstepFunction with cloud Function
        // since readOnly validation prevents deletion
        // onstepFunction.destroyRecord();
        const projectTracks = await this.model.get('tracks');
        projectTracks.removeObject(track);
        await this.model.save();
        track.destroyRecord();
      },

      // TODO implement cloud function to delete tracks on project delete
      async delete(project) {
        await project.destroyRecord();
        this.transitionToRoute('user');
      }

    }
  });

  _exports.default = _default;
});