define("euclidean-cracked/components/mdc-icon-button-link-to", ["exports", "ember-cli-mdc-icon-button/components/mdc-icon-button-link-to"], function (_exports, _mdcIconButtonLinkTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _mdcIconButtonLinkTo.default;
    }
  });
});