define("euclidean-cracked/utils/example-init-functions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const signalChains = [{
    name: 'default',
    code: "\n__()\n  .sampler({\n    id: this.samplerId,\n    path: this.path,\n    class: this.className\n  })\n  .gain({\n    id: this.gainId, // assign gainID to connect to the track's main volume slider\n  })\n  .gain({ // onstep gain controlled by multislider\n    class: this.className,\n  })\n  .connect('#master-compressor');\n"
  }, {
    name: 'delay',
    code: "\n  __()\n  .sampler({\n    id: this.samplerId,\n    path: this.path,\n    class: this.className\n  })\n  .gain({ \n    id: this.gainId, // assign gainID to connect to the track's main volume slider\n  })\n  .delay({\n    class: this.className,\n    damping:.9, \n    cutoff:3000,\n    feedback:0.9,\n    delay:3,\n  })\n  .connect('#master-compressor');\n  "
  }, {
    name: 'lowpass filter',
    code: "\n  __()\n  .sampler({\n    id: this.samplerId,\n    path: this.path,\n    class: this.className\n  })\n  .gain({ \n    id: this.gainId, // assign gainID to connect to the track's main volume slider\n  })\n  .lowpass({\n    class: this.className,\n    frequency: 2000,\n    q:8\n  })\n  .connect('#master-compressor');\n"
  }];
  const lfo = [{
    name: '',
    code: ""
  }, {
    name: '',
    code: ""
  }];
  var _default = [{
    sectionName: 'signal chains',
    examples: signalChains
  }, {
    sectionName: 'LFO config',
    examples: lfo
  }];
  _exports.default = _default;
});