define("euclidean-cracked/components/mdc-top-app-bar-navigate-up-to", ["exports", "ember-cli-mdc-top-app-bar/components/mdc-top-app-bar-navigate-up-to"], function (_exports, _mdcTopAppBarNavigateUpTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _mdcTopAppBarNavigateUpTo.default;
    }
  });
});