define("euclidean-cracked/components/nav-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    session: Ember.inject.service(),
    isAnonymous: Ember.computed.equal('session.provider', 'anonymous'),
    actions: {
      toggleMenu() {
        this.toggleProperty('isOpen');
      },

      async save(record) {
        record.save();
        this.set('isEditingTitle', false);
      }

    }
  });

  _exports.default = _default;
});