define("euclidean-cracked/services/session", ["exports", "torii/services/torii-session"], function (_exports, _toriiSession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _toriiSession.default.extend({
    currentUserModel: null
  });

  _exports.default = _default;
});