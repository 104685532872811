define("euclidean-cracked/components/mdc-menu-selection-group", ["exports", "ember-cli-mdc-menu/components/mdc-menu-selection-group"], function (_exports, _mdcMenuSelectionGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _mdcMenuSelectionGroup.default;
    }
  });
});