define("euclidean-cracked/components/mdc-drawer-scrim", ["exports", "ember-cli-mdc-drawer/components/mdc-drawer-scrim"], function (_exports, _mdcDrawerScrim) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _mdcDrawerScrim.default;
    }
  });
});