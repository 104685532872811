define("euclidean-cracked/templates/components/track-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V+1X7Y5a",
    "block": "{\"symbols\":[\"trackControl\",\"trackControl\"],\"statements\":[[7,\"section\"],[11,\"class\",\"pa3 w-100 overflow-hidden\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex justify-between items-center\"],[9],[0,\"\\n    \"],[7,\"h3\"],[9],[0,\"Automation Controls\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"flex flex-column h-100 w-100 mb4\"],[9],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"track-ctrl-btn-list list flex pb3 ph0 ma0 overflow-scroll mw6\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"track\",\"trackControls\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[11,\"class\",\"\"],[9],[0,\"\\n\"],[4,\"mdc-button\",null,[[\"click\",\"class\",\"title\",\"style\"],[[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"currentTrackControlId\"]]],null],[22,2,[\"id\"]]],null],\"f7\",[27,\"concat\",[\"audio node \",[22,2,[\"nodeName\"]],\" : attribute: \",[22,2,[\"nodeAttr\"]]],null],[27,\"if\",[[27,\"eq\",[[22,2,[\"id\"]],[23,[\"currentTrackControlId\"]]],null],\"raised\"],null]]],{\"statements\":[[0,\"            \"],[1,[22,2,[\"nodeName\"]],false],[0,\":\"],[1,[22,2,[\"nodeAttr\"]],false],[0,\" \\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[10],[0,\"\\n    \\n\"],[4,\"each\",[[23,[\"track\",\"trackControls\"]]],null,{\"statements\":[[4,\"if\",[[27,\"and\",[[27,\"eq\",[[22,1,[\"id\"]],[23,[\"currentTrackControlId\"]]],null],[22,1,[\"isLoaded\"]],[27,\"not\",[[22,1,[\"isDeleted\"]]],null]],null]],null,{\"statements\":[[0,\"        \"],[5,\"track-control-wrapper\",[],[[\"@track\",\"@trackControl\",\"@stepIndex\"],[[21,\"track\"],[22,1,[]],[21,\"stepIndex\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "euclidean-cracked/templates/components/track-controls.hbs"
    }
  });

  _exports.default = _default;
});