define("euclidean-cracked/components/ui-slider", ["exports", "euclidean-cracked/mixins/nexus-ui"], function (_exports, _nexusUi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_nexusUi.default, {
    classNames: ['ui-slider'],
    tagName: 'span',

    didInsertElement() {
      this._super(...arguments);

      this._nexusInit();
    },

    ElementName: 'Slider',
    ElementOptions: Ember.computed('max', 'step', 'value', 'size', {
      get() {
        return {
          'size': this.size || [20, 70],
          'mode': 'relative',
          // "absolute" or "relative"
          'min': 0,
          'max': this.max || 1,
          'step': this.step || 0,
          'value': this.value
        };
      }

    }),

    afterInitNexus(NexusElement) {
      NexusElement.colorize('accent', '#52ebff');
      NexusElement.colorize('fill', '#ffffff');
    }

  });

  _exports.default = _default;
});