define("euclidean-cracked/routes/user/new", ["exports", "euclidean-cracked/utils/clean"], function (_exports, _clean) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    beforeModel() {
      let hash = Math.random().toString(36).substring(5);
      let name = "Untitled-".concat(hash);
      this.createProject(name, true, true);
    },

    async createProject(title, publicEditable, publicVisible) {
      let user = null;
      let slug = (0, _clean.default)(title);
      let date = new Date();
      let project = this.store.createRecord('project', {
        title,
        publicEditable,
        publicVisible,
        slug,
        date
      }); // TODO: get auth type here
      // allow custom username for anonymous or google auth

      user = this.session.get('currentUserModel');
      await user.get('projects').addObject(project);
      project = await project.save();
      await user.save();
      return this.transitionTo('user.creator.project', user, Ember.get(project, 'slug'));
    }

  });

  _exports.default = _default;
});