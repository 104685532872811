define("euclidean-cracked/components/project-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs() {
      this._super(...arguments);
    },

    actions: {
      setCurrentProject(project) {
        Ember.set(this, 'currentProject', project);
      }

    }
  });

  _exports.default = _default;
});