define("euclidean-cracked/templates/components/drum-file-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "57ZXNMqU",
    "block": "{\"symbols\":[\"directory\",\"idx\"],\"statements\":[[4,\"mdc-dialog\",null,[[\"title\",\"accept\",\"cancelButtonText\",\"show\",\"scrollable\"],[\"Choose a drum sound\",[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"isOpen\"]]],null],true],null],\"done\",[23,[\"isOpen\"]],false]],{\"statements\":[[4,\"if\",[[27,\"or\",[[23,[\"saveTrackTask\",\"isRunning\"]],[23,[\"fetchDirectory\",\"isRunning\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"mdc-linear-progress\",null,[[\"class\",\"indeterminate\"],[\"absolute bottom-0 w-100\",true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[11,\"class\",\"drum-file-picker\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"directories\"]]],null,{\"statements\":[[0,\"      \"],[5,\"file-directory\",[],[[\"@directory\",\"@filepath\",\"@idx\",\"@onSelect\"],[[22,1,[]],[23,[\"track\",\"filepath\"]],[22,2,[]],[27,\"action\",[[22,0,[]],\"onSelect\"],null]]]],[0,\"\\n\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "euclidean-cracked/templates/components/drum-file-picker.hbs"
    }
  });

  _exports.default = _default;
});