define("euclidean-cracked/models/track-control", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    Implementation notes on custom track controls:
    
    interfaceName: slider, multislider, toggle, xy control
  
    variable name: reference to an object that holds the current state of the interface, 
      available in the track function editor's scope
    
    the add-control menu will have options to add custom controls or pre-baked controls (ie filters, chromatic pitch)
  
  */
  var _default = _emberData.default.Model.extend({
    track: _emberData.default.belongsTo('track'),
    interfaceName: _emberData.default.attr('string'),
    controlData: _emberData.default.attr('string', {
      defaultValue() {
        return Array.from(new Array(16), () => {
          return 0.5;
        }).join(',');
      }

    }),
    controlDataArray: Ember.computed('controlData.[]', {
      get() {
        return this.controlData ? this.controlData.split(',').map(val => Math.max(Math.min(+val, this.max), this.min)) : [];
      }

    }),

    setDefaultValue() {
      const controlData = this.get('controlDataArray').map(() => this.get('defaultVal') || 0).join(',');
      this.set('controlData', controlData);
    },

    nodeSelector: _emberData.default.attr('string'),
    nodeName: _emberData.default.attr('string'),
    nodeAttr: _emberData.default.attr('string'),
    uniqueNameAttr: Ember.computed('nodeName', 'nodeAttr', {
      get() {
        return "".concat(this.nodeName, "-").concat(this.nodeAttr);
      }

    }),
    min: _emberData.default.attr('number', {
      defaultValue() {
        return 0;
      }

    }),
    max: _emberData.default.attr('number', {
      defaultValue() {
        return 1;
      }

    }),
    defaultVal: _emberData.default.attr('number', {
      defaultValue() {
        return 1;
      }

    }),
    sequence: Ember.computed.alias('track.sequence')
  });

  _exports.default = _default;
});