define("euclidean-cracked/components/drum-file-picker", ["exports", "euclidean-cracked/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      Ember.set(this, 'directories', []);
    },

    didReceiveAttrs() {
      this._super(...arguments);

      let path = '';

      if (this.track && this.track.filepath) {
        this.initDirectoryFromTrack.perform(this.track.filepath);
      } else {
        this.updateDirectories.perform(path);
      }
    },

    fetchDirectory: (0, _emberConcurrency.task)(function* (path) {
      const url = "".concat(_environment.default.DRUMSERVER_HOST).concat(path);
      const response = yield fetch(url);
      const json = yield response.json();
      return json;
    }).evented(),

    parseResponse(content) {
      const {
        dirs,
        audio
      } = content; // prefer type as audio if contains both

      content.type = audio.length ? 'audio' : 'dir';
      content.choices = audio.length ? audio : dirs; // choices will be either audio or dirs
      //  no need to retain these

      delete content.audio;
      delete content.dirs;
      return content;
    },

    initDirectoryFromTrack: (0, _emberConcurrency.task)(function* (filepath) {
      let path = filepath.split('/');
      path.pop();
      let response = yield this.fetchDirectory.perform(path.join('/'));

      if (response.ancestor_tree) {
        let directories = response.ancestor_tree.map(dir => {
          return this.parseResponse(dir);
        });
        Ember.set(this, 'directories', directories);
      }
    }),
    updateDirectories: (0, _emberConcurrency.task)(function* (pathToFetch) {
      let response = yield this.fetchDirectory.perform(pathToFetch);
      let directory = this.parseResponse(response); // clear any child directories when clicking back higher up the tree

      const pathDepth = directory.path.split('/').filter(s => s.length).length;

      while (this.directories.length > pathDepth) {
        this.directories.pop();
      }

      this.directories.pushObject(directory);
    }).keepLatest(),
    actions: {
      onSelect(directory, choice) {
        Ember.set(directory, 'currentSelection', choice);
        let newPath = "".concat(directory.path).concat(choice);
        let type = directory.type;

        if (type === 'dir') {
          newPath = "".concat(newPath, "/");
          this.updateDirectories.perform(newPath);
        } else if (type === 'audio') {
          this.track.set('filepath', newPath);
          this.saveTrackTask.perform(this.track);
        }
      }

    }
  });

  _exports.default = _default;
});