define("euclidean-cracked/components/track-scripts", ["exports", "euclidean-cracked/utils/example-init-functions", "euclidean-cracked/utils/example-onstep-functions"], function (_exports, _exampleInitFunctions, _exampleOnstepFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      this.set('functionType', 'init');
    },

    // eslint-disable-next-line complexity
    async didReceiveAttrs() {
      this._super(...arguments);

      if (this.track && this.functionType) {
        const onstepFunctionRecord = await this.track.get('onstepFunction');
        const initFunctionRecord = await this.track.get('initFunction');

        if (!onstepFunctionRecord || !initFunctionRecord) {
          throw "".concat(this.functionType, "Function is null, Cloud function should enforce this never happens");
        }

        this.set('onstepFunctionRecord', onstepFunctionRecord);
        this.set('initFunctionRecord', initFunctionRecord);
      }
    },

    customFunctionRef: Ember.computed('functionType', {
      get() {
        // returns either onstepFunctionRef or initFunctionRef
        return this.track.get("".concat(this.functionType, "FunctionRef"));
      }

    }),
    exampleFunctions: Ember.computed('functionType', {
      get() {
        switch (this.functionType) {
          case 'init':
            return _exampleInitFunctions.default;

          case 'onstep':
            return _exampleOnstepFunctions.default;
        }
      }

    }),
    customFunctionRecord: Ember.computed('initFunctionRecord', 'onstepFunctionRecord', 'functionType', {
      get() {
        if (this.functionType === 'init') {
          return this.initFunctionRecord;
        } else if (this.functionType === 'onstep') {
          return this.onstepFunctionRecord;
        }
      }

    })
  });

  _exports.default = _default;
});