define("euclidean-cracked/templates/components/index-project-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qwUYyYnc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"mdc-card\",null,[[\"outlined\",\"class\"],[true,\"ma3 pa3\"]],{\"statements\":[[0,\"    \"],[2,\" <button class=\\\"btn play\\\">{{fa-icon \\\"play\\\"}}</button>  \"],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"mdc-typography mdc-typography--headline6\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"user.creator.project\",[23,[\"session\",\"currentUserModel\"]],[23,[\"project\"]]],[[\"class\"],[\"link\"]],{\"statements\":[[0,\"      \"],[1,[23,[\"project\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[4,\"mdc-card-media\",null,[[\"scale\"],[\"16-9\"]],{\"statements\":[[4,\"mdc-card-media-content\",null,null,{\"statements\":[],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"mdc-card-actions\",null,null,{\"statements\":[[4,\"mdc-card-action-icons\",null,null,{\"statements\":[[0,\"      \"],[14,1],[0,\"\\n\"],[4,\"if\",[[23,[\"canDelete\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"mdc-card-action-icon\",[\"delete\"],[[\"title\",\"click\"],[\"Delete\",[27,\"action\",[[22,0,[]],\"deleteProject\",[23,[\"project\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\\n  \\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "euclidean-cracked/templates/components/index-project-item.hbs"
    }
  });

  _exports.default = _default;
});