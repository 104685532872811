define("euclidean-cracked/templates/components/file-directory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "06OTQ+On",
    "block": "{\"symbols\":[\"choice\"],\"statements\":[[7,\"div\"],[11,\"class\",\"relative pt4 min-width-12\"],[9],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"absolute black top-0 w-100 bg-white pl3\"],[9],[1,[27,\"if\",[[27,\"eq\",[[23,[\"directory\",\"type\"]],\"audio\"],null],\"Sounds\",\"Drum Machine\"],null],false],[10],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"directory-wrapper list ph0 mt0\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"directory\",\"choices\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[12,\"class\",[28,[[27,\"if\",[[27,\"eq\",[[22,1,[]],[23,[\"selected\"]]],null],\"selected\"],null]]]],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"pl3\"],[3,\"action\",[[22,0,[]],\"onSelect\",[23,[\"directory\"]],[22,1,[]]]],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"directory\",\"type\"]],\"audio\"],null]],null,{\"statements\":[[0,\"            \"],[1,[27,\"filename-ui\",[[22,1,[]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[22,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "euclidean-cracked/templates/components/file-directory.hbs"
    }
  });

  _exports.default = _default;
});