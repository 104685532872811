define("euclidean-cracked/components/edit-project", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isEditing: false,
    classNames: 'edit-project flex',
    isAllowed: Ember.computed('project.creator.uid', 'session.currentUserModel.uid', {
      get() {
        const currentUser = Ember.get(this, 'session.currentUserModel.uid');
        const creatorPromise = Ember.get(this, 'project.creator').then(creator => {
          if (creator) {
            return Ember.get(creator, 'uid') === currentUser;
          } else {
            return false;
          }
        });
        return _emberData.default.PromiseObject.create({
          promise: creatorPromise
        });
      }

    }),
    actions: {
      save() {
        let sessionName = Ember.get(this, 'session.currentUserModel.uid');

        if (sessionName === this.project.get('creator.uid')) {
          Ember.set(this, 'isEditing', false);
          Ember.get(this, 'onSaveProject')(this.project);
        } else {
          alert('Sorry not authorized');
        }
      },

      edit() {
        Ember.set(this, 'isEditing', true);
      },

      deleteProject() {
        this.onDelete(this.project);
        Ember.set(this, 'isEditing', false);
      }

    }
  });

  _exports.default = _default;
});