define("euclidean-cracked/components/track-controls", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: '',

    async init() {
      this._super(...arguments);

      await (0, _emberConcurrency.waitForProperty)(this.track, 'trackControls');
      this.set('currentTrackControlId', this.track.get('trackControls.firstObject.id'));
    }

  });

  _exports.default = _default;
});