define("euclidean-cracked/models/custom-function", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    projectCreatorUid: _emberData.default.attr('string'),
    // the text of the function string as the user types
    // (not ever loaded into an actual javascript function)
    editorContent: _emberData.default.attr('string', {
      defaultValue() {
        return '';
      }

    }),
    // copied from editorContent when user clicks load function
    functionPreCheck: _emberData.default.attr('string'),
    // always copied from functionPreCheck in cloud function
    // after submitted and checked for forbidden tokens
    // never settable by the client
    function: _emberData.default.attr('string', {
      readOnly: true
    }),
    // unsafe javascript keywords returned from cloud function
    illegalTokens: _emberData.default.attr('string', {
      readOnly: true
    }),

    // create the function referecne and bind it's scope
    createRef(track, ...args) {
      let functionRef;

      if (this.function) {
        try {
          functionRef = new Function(args, this.function).bind(track.get('customFunctionScope'));
          return functionRef;
        } catch (e) {
          alert('problem with function', e.message);
        }
      }
    }

  });

  _exports.default = _default;
});