define("euclidean-cracked/templates/components/track-container-ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ED/R/7mq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"flex h-100 flex-wrap ml4\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n  \"],[1,[27,\"ui-step-sequencer\",null,[[\"sequence\",\"stepIndex\"],[[23,[\"track\",\"sequence\"]],[23,[\"track\",\"stepIndex\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[27,\"mdc-icon-button\",null,[[\"icon\",\"title\",\"class\",\"click\"],[\"close\",\"Delete Track\",\"dark-red delete-track-btn\",[27,\"action\",[[22,0,[]],\"deleteTrack\",[23,[\"track\"]]],[[\"bubbles\"],[false]]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"isActive\"]]],null,{\"statements\":[[0,\"  \"],[5,\"current-track-footer\",[],[[\"@track\",\"@saveTrackTask\"],[[21,\"track\"],[21,\"saveTrackTask\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "euclidean-cracked/templates/components/track-container-ui.hbs"
    }
  });

  _exports.default = _default;
});