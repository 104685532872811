define("euclidean-cracked/mixins/sequence-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * TODO:
   * these methods / computed properties should be moved to the 
   * track model so they can be accessed by the audio service (and downstream components) directly
   * from the track record
   */
  var _default = Ember.Mixin.create({
    // gainStepArray: computed('track.gainStepSeq', arraySequenceComputed('gainStepSeq')),
    // speedStepArray: computed('track.speedStepSeq', arraySequenceComputed('speedStepSeq')),
    // loopEndStepArray: computed('track.loopEndStepSeq', arraySequenceComputed('loopEndStepSeq')),
    stringToArray(stringKey) {
      let trackData = Ember.get(this, "track.".concat(stringKey));

      if (trackData) {
        return Ember.get(this, "track.".concat(stringKey)).split(',').map(str => parseFloat(str));
      } else {
        return this.gainStepDefault;
      }
    },

    saveArrayAsString(stringKey, array) {
      let track = this.track;
      let seqString = array.toString();
      track.set(stringKey, seqString);
      track.save();
    },

    setParamsAudioService(paramKey, stepArray) {
      let serviceTracks = Ember.get(this, 'audioService.tracks');
      let trackRef = serviceTracks.findBy('trackId', Ember.get(this, 'track.id'));
      Ember.set(trackRef, paramKey, stepArray);
    }

  });

  _exports.default = _default;
});