define("euclidean-cracked/components/mdc-top-app-bar-title", ["exports", "ember-cli-mdc-top-app-bar/components/mdc-top-app-bar-title"], function (_exports, _mdcTopAppBarTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _mdcTopAppBarTitle.default;
    }
  });
});