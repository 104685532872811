define("euclidean-cracked/models/track", ["exports", "euclidean-cracked/utils/euclidean", "ember-data/attr", "ember-data/relationships", "euclidean-cracked/models/track-sampler-instance"], function (_exports, _euclidean, _attr, _relationships, _trackSamplerInstance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _trackSamplerInstance.default.extend({
    project: (0, _relationships.belongsTo)('project'),
    trackControls: (0, _relationships.hasMany)('track-control'),
    // belongs on project model but used here as
    // convenience for firebase .write rules
    projectCreatorUid: (0, _attr.default)('string'),
    // written only by same property on project
    // again, convenience for firebase auth rules
    publicEditable: (0, _attr.default)('boolean'),
    isLooping: (0, _attr.default)('boolean'),
    filepath: (0, _attr.default)('string', {
      // TODO request file server api
      // labelled categories for random kick, snare, hat etc
      defaultValue() {
        const files = ['/Ace Tone Rhythm Ace/KICK1.mp3', '/Ace Tone Rhythm Ace/SNARE1.mp3', '/Korg/Korg DDD5/DDD5 RIM.mp3', '/Ace Tone Rhythm Ace/HHCL.mp3'];

        const idx = __.random(0, files.length - 1);

        return files[idx];
      }

    }),
    filename: Ember.computed('filepath', {
      get() {
        if (this.filepath) {
          let filename = this.filepath.replace(/^.*[\\/]/, '');
          filename = filename.split('.');
          filename.pop();
          return filename;
        }
      }

    }),
    onstepFunction: (0, _relationships.belongsTo)('customFunction'),
    initFunction: (0, _relationships.belongsTo)('customFunction'),
    hits: (0, _attr.default)('number', {
      defaultValue() {
        return __.random(1, 4);
      }

    }),
    steps: (0, _attr.default)('number', {
      defaultValue() {
        return 16;
      }

    }),
    offset: (0, _attr.default)('number', {
      defaultValue() {
        return 0;
      }

    }),
    sequence: Ember.computed('hits', 'steps', 'offset', {
      get() {
        const hasHits = typeof this.hits !== 'undefined';
        const hasSteps = typeof this.steps !== 'undefined';

        if (hasHits && hasSteps) {
          return (0, _euclidean.default)(this.hits, this.steps, this.offset);
        }
      }

    }),

    /* 
      this could move to a track UI subclass
      params to fit multislider to same width as sequence
    */
    multisliderSize: Ember.computed('sequence.length', {
      get() {
        let uiStepSize = 40;
        let width = uiStepSize * .85 * this.sequence.length;
        let height = 120;
        return [width, height];
      }

    }),
    gain: (0, _attr.default)('number', {
      defaultValue() {
        return 0.5;
      }

    }) // TODO serialize/normalize to string for firebase
    // gainStepSeq: attr('firestore.array'),

  });

  _exports.default = _default;
});