define("euclidean-cracked/components/file-directory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      this._super(...arguments);

      if (this.filepath) {
        const pathDirs = this.filepath.split('/').filter(dir => dir);
        const selected = pathDirs[this.idx];
        this.set('selected', selected);
      }

      Ember.run.next(() => {
        // TODO handle if none selected condition (tho shouldnt happen)
        let selector = this.$('.selected');

        if (selector && selector.length) {
          selector = this.$('.selected').get();
          selector[0].scrollIntoView();
        }
      });
    },

    actions: {
      onSelect(dir, choice) {
        this.set('selected', choice);
        this.onSelect(dir, choice);
      }

    }
  });

  _exports.default = _default;
});