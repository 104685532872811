define("euclidean-cracked/templates/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6o6m/o63",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"nav-bar\",null,[[\"session\",\"onLogin\",\"onLogout\",\"currentProject\"],[[23,[\"session\"]],[27,\"route-action\",[\"login\"],null],[27,\"route-action\",[\"logout\"],null],[23,[\"currentProject\"]]]]],false],[0,\"\\n\\n\\n\"],[4,\"mdc-content\",null,[[\"class\"],[\"h-100 bg-dark-gray\"]],{\"statements\":[[0,\"  \"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "euclidean-cracked/templates/user.hbs"
    }
  });

  _exports.default = _default;
});