define("euclidean-cracked/components/edit-track", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['edit-track border'],

    didInsertElement() {
      this._super(...arguments);

      this.send('switchInterface', 'filePicker');
    }

  });

  _exports.default = _default;
});