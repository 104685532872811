define("euclidean-cracked/components/index-project-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    tagName: '',
    canDelete: Ember.computed('session.currentUser.uid', 'project.creator.uid', {
      get() {
        return this.session.get('currentUser.uid') === this.project.get('creator.uid');
      }

    }),
    actions: {
      async deleteProject(project) {
        let tracks = await project.tracks.toArray();

        for (const track of tracks) {
          await track.destroyRecord();
        }

        await project.destroyRecord();
      }

    }
  });

  _exports.default = _default;
});