define("euclidean-cracked/templates/components/project-active-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eyfav0RF",
    "block": "{\"symbols\":[\"activeUser\",\"&default\"],\"statements\":[[4,\"if\",[[23,[\"visible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h4\"],[11,\"class\",\"mv0\"],[9],[0,\"\\n    active users\\n  \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"project\",\"activeUsers\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"online\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[12,\"class\",[28,[\"user \",[27,\"if\",[[22,1,[\"ondivne\"]],\"online\"],null]]]],[9],[0,\"\\n            \"],[7,\"img\"],[12,\"src\",[28,[[22,1,[\"avatar\"]]]]],[12,\"title\",[28,[[22,1,[\"username\"]]]]],[9],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null],[14,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "euclidean-cracked/templates/components/project-active-users.hbs"
    }
  });

  _exports.default = _default;
});