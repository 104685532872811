define("euclidean-cracked/components/mdc-checkbox-with-label", ["exports", "ember-cli-mdc-checkbox/components/mdc-checkbox-with-label"], function (_exports, _mdcCheckboxWithLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _mdcCheckboxWithLabel.default;
    }
  });
});