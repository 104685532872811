define("euclidean-cracked/components/track-function-editor", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // customFunctionRecord is the model that saves custom function as string
    classNames: ['track-function-editor'],
    tagName: '',
    function: Ember.computed.alias('customFunctionRecord.function'),
    illegalTokens: Ember.computed.reads('customFunctionRecord.illegalTokens'),
    // code in text editor
    editorContent: Ember.computed.alias('customFunctionRecord.editorContent'),
    canSubmit: Ember.computed.and('editorContent.length'),
    cantSubmit: Ember.computed.or('!canSubmit', 'functionIsLoaded'),
    functionIsLoaded: Ember.computed('function', 'editorContent', {
      get() {
        return this.function === this.editorContent && this.customFunctionRef;
      }

    }),
    showDiscardBtn: Ember.computed.not('hasUnloadedCode'),
    // set property to cusotmFunction model then save
    saveFunctionTask: (0, _emberConcurrency.task)(function* (property, value) {
      // yield proxy to model record
      const customFunctionRecord = yield this.customFunctionRecord;
      customFunctionRecord.set(property, value);
      yield customFunctionRecord.save();
    }),
    applyFunction: (0, _emberConcurrency.task)(function* () {
      // apply the editor content to functionPreCheck
      // which then gets checked in cloud function
      yield this.saveFunctionTask.perform('functionPreCheck', this.editorContent); // after save is called, functionPreCheck has a value
      // until the function checker sets it null and either sets
      // the function property, or returns illegal keywords

      yield (0, _emberConcurrency.waitForProperty)(this, 'customFunctionRecord.functionPreCheck', null); // the cloud function check succeeded if function + editor are identical

      if (this.customFunctionRecord.get('function') === this.editorContent) {
        yield this.track.initializeSampler.perform();
      }
      /**
       * TODO: set a timeout condition for this method
       * if the cloud function fails to respond, it can still set
       * the track function locally for this user (also display an error message)
       */

    }),

    verifyCustomFunction() {
      // customFunctionRecord.function can only be written by a cloud function
      // that filters out dangerous tokens
      let isSafe = !this.get('illegalTokens');

      if (isSafe && this.function) {
        this.track.applyCustomFunction();
      }
    },

    injectExample: (0, _emberConcurrency.task)(function* (code) {
      if (this.editorContent) {
        if (!window.confirm('overwrite existing script?')) {
          return;
        }
      }

      yield this.saveFunctionTask.perform('editorContent', code);
      yield this.applyFunction.perform();
    }),
    // TODO
    // add track save task and invoke that everywhere
    actions: {
      submitCode() {
        this.applyFunction.perform();
      },

      onUpdateEditor(content) {
        this.saveFunctionTask.perform('editorContent', content);
      },

      discardChanges() {
        this.saveFunctionTask.perform('editorContent', this.function);
      },

      disableFunction() {
        // TODO: fix
        this.saveFunctionTask.perform('functionPreCheck', '');
        this.track.set('customFunctionRef', null);
      }

    }
  });

  _exports.default = _default;
});