define("euclidean-cracked/components/ui-sequence-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      updateSequenceParam(param, value) {
        if (this.hits > this.steps) {
          this.onUpdateSequenceParam('steps', value);
        }

        this.onUpdateSequenceParam(param, value);
      }

    }
  });

  _exports.default = _default;
});