define("euclidean-cracked/templates/components/track-scripts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L79x3t8C",
    "block": "{\"symbols\":[],\"statements\":[[5,\"track-function-editor\",[[11,\"class\",\"w-100 z-2 h-auto overflow-scroll absolute bottom-0 bg-yellow pa3\"]],[[\"@track\",\"@exampleFunctions\",\"@customFunctionRef\",\"@customFunctionRecord\"],[[21,\"track\"],[21,\"exampleFunctions\"],[21,\"customFunctionRef\"],[21,\"customFunctionRecord\"]]],{\"statements\":[[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"flex pa0 ma0\"],[9],[0,\"\\n\"],[4,\"mdc-button\",null,[[\"click\",\"class\",\"title\",\"style\"],[[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"functionType\"]]],null],\"init\"],null],\"f7\",\"Setup function runs once to initialize the track. This is where you define the track's audio nodes.\",[27,\"if\",[[27,\"eq\",[[23,[\"functionType\"]],\"init\"],null],\"raised\"],null]]],{\"statements\":[[0,\"      Setup\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"mdc-button\",null,[[\"click\",\"class\",\"title\",\"style\"],[[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"functionType\"]]],null],\"onstep\"],null],\"f7\",\"On Step function runs on each step of the sequence. This is where you write code to modulate the tracks parameters\",[27,\"if\",[[27,\"eq\",[[23,[\"functionType\"]],\"onstep\"],null],\"raised\"],null]]],{\"statements\":[[0,\"      On Step\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "euclidean-cracked/templates/components/track-scripts.hbs"
    }
  });

  _exports.default = _default;
});