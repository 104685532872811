define("euclidean-cracked/components/current-track-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',

    init() {
      this._super(...arguments);

      this.set('gainSliderSize', [20, 120]);
      this.set('visibleInterface', 'rhythm');
    },

    actions: {
      updateSequenceParam(parameter, value) {
        // NOTE: try using store.push to instantly update the UI
        // if server roundtrip causes UI problems
        this.track.set(parameter, value);
        this.saveTrackTask.perform(this.track);
      }

    }
  });

  _exports.default = _default;
});