define("euclidean-cracked/components/ui-step-sequencer", ["exports", "euclidean-cracked/mixins/nexus-ui", "ember-concurrency"], function (_exports, _nexusUi, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_nexusUi.default, {
    classNames: ['ui-step-sequencer', 'flex items-center'],
    ElementName: 'Sequencer',
    ElementOptions: Ember.computed('width', 'length', 'value', {
      get() {
        // TODO: generalize ui element sizes to a global config
        // (or nexus mixin)
        return {
          size: [this.width, 30],
          mode: 'toggle',
          rows: 1,
          columns: this.length
        };
      }

    }),
    length: Ember.computed('sequence.length', {
      get() {
        return Ember.get(this, 'sequence.length');
      }

    }),
    width: Ember.computed('length', {
      get() {
        return this.length * 34.1;
      }

    }),
    // eslint-disable-next-line ember/no-observers
    // eslint-disable-next-line complexity
    onStepChange: Ember.observer('stepIndex', function () {
      let sequencer = this.NexusElement;

      if (sequencer) {
        if (sequencer && typeof sequencer.stepper.value === 'number') {
          let step = this.stepIndex % sequencer.stepper.max - 2;

          if (!this.stepIndex) {
            step = sequencer.stepper.max - 2;
          }

          sequencer.stepper.value = step;
        }

        sequencer.next();
      }
    }),

    init() {
      this._super(...arguments);

      this.renderWhenReady();
    },

    async renderWhenReady() {
      await (0, _emberConcurrency.waitForProperty)(this, 'sequence');
      this.updateAndCacheSequence();
    },

    didUpdateAttrs() {
      this._super(...arguments);

      this.updateAndCacheSequence();
    },

    updateAndCacheSequence() {
      let refreshOnUpdate = this.sequence !== this._sequence;

      if (refreshOnUpdate) {
        this._nexusInit();

        if (this.sequence) {
          let sequencer = this.NexusElement;
          sequencer.matrix.set.row(0, this.sequence);
        }
      }

      Ember.set(this, '_sequence', this.sequence);
    },

    afterInitNexus(NexusElement) {
      NexusElement.colorize('accent', '#52ebff');
      NexusElement.colorize('fill', '#ffffff');
      NexusElement.colorize('mediumLight', '#d9534f');
    }

  });

  _exports.default = _default;
});