define("euclidean-cracked/templates/components/audio-project-sequencer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bPJ7AOhg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"ba flex items-center ph3\"],[9],[0,\"\\n  \"],[1,[27,\"mdc-icon-button\",null,[[\"icon\",\"type\",\"click\"],[\"fast_rewind\",\"button\",[27,\"action\",[[22,0,[]],\"reset\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"project\",\"isPlaying\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"mdc-icon-button\",null,[[\"icon\",\"type\",\"click\"],[\"stop\",\"button\",[27,\"action\",[[22,0,[]],\"stop\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"mdc-icon-button\",null,[[\"icon\",\"type\",\"click\"],[\"play_arrow\",\"button\",[27,\"action\",[[22,0,[]],\"start\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[7,\"label\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"value\",\"change\",\"class\",\"type\"],[[23,[\"project\",\"bpm\"]],[27,\"perform\",[[23,[\"setBpmTask\"]]],null],\"w3 bg-transparent bw1 b--black\",\"number\"]]],false],[0,\" BPM\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "euclidean-cracked/templates/components/audio-project-sequencer.hbs"
    }
  });

  _exports.default = _default;
});