define("euclidean-cracked/mixins/nexus-ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    nexusId: Ember.computed('elementId', {
      get() {
        // FIXME: ember ids deprecated soon
        // strip 'ember' out of component id and use as
        // nexus instance id
        return parseInt(Ember.get(this, 'elementId').substring(5));
      }

    }),

    didReceiveAttrs() {
      this._super(...arguments);

      if (Ember.get(this, 'value')) {
        Ember.run.next(() => {
          Ember.set(this, 'NexusElement.value', Ember.get(this, 'value'));
        });
      }
    },

    _nexusInit() {
      if (Ember.get(this, 'NexusElement')) {
        Ember.get(this, 'NexusElement').destroy();
      } // components that use this mixin must set ElementName and ElementOptions


      let ElementOptions = Ember.get(this, 'ElementOptions');
      let ElementName = Ember.get(this, 'ElementName');
      let NexusElement = new Nexus[ElementName]("#".concat(Ember.get(this, 'nexusId')), ElementOptions);
      Ember.set(this, 'NexusElement', NexusElement);

      if (Ember.get(this, 'onChangeValue')) {
        NexusElement.on('change', v => {
          if (Ember.get(this, 'value') !== v) {
            Ember.set(this, 'value', v);
          } // components using this mixin must 
          // have an action onChangeValue passed in


          Ember.get(this, 'onChangeValue')(v);
        });
      }

      if (typeof Ember.get(this, 'afterInitNexus') === 'function') {
        // TODO: refactor this so that it is only called once
        // is there a better way than caching nexusElement and checking if
        // it previously existed?
        Ember.get(this, 'afterInitNexus')(NexusElement);
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      if (Ember.get(this, 'NexusElement')) {
        Ember.get(this, 'NexusElement').destroy();
      }
    }

  });

  _exports.default = _default;
});