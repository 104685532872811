define("euclidean-cracked/templates/user/my-projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KGMhYai1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\"],[11,\"class\",\"ma3 white\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"My Projects:\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[27,\"project-list\",null,[[\"projects\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "euclidean-cracked/templates/user/my-projects.hbs"
    }
  });

  _exports.default = _default;
});