define("euclidean-cracked/components/mdc-drawer", ["exports", "ember-cli-mdc-drawer/components/mdc-drawer"], function (_exports, _mdcDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _mdcDrawer.default;
    }
  });
});