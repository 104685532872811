define("euclidean-cracked/routes/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const getAnonDefaults = function () {
    let hash = Math.random().toString(36).substring(7);
    let avatar = "https://api.adorable.io/avatars/100".concat(hash, ".png");
    let username = "anonymous-".concat(hash);
    return {
      avatar,
      username
    };
  };

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    isAnonymous: Ember.computed.equal('session.provider', 'anonymous'),

    async beforeModel() {
      // fire a request to wake up heroku drumserver
      fetch('https://drumserver.herokuapp.com/');

      try {
        await this.session.fetch();
      } catch (error) {
        await this.openSession('anonymous');
      }
    },

    async model() {
      const userRecordsQuery = await this.store.query('user', {
        orderBy: 'uid',
        equalTo: this.session.get('currentUser.uid'),
        limitToFirst: 1
      });

      if (userRecordsQuery.length === 0) {
        return this.createAnonUser();
      } else {
        return userRecordsQuery.get('firstObject');
      }
    },

    afterModel(user) {
      this.session.set('currentUserModel', user);
      return user.ref().child('online').onDisconnect().set(false);
    },

    createAnonUser() {
      // the object returned from google authenticator (not yet an ember model)
      const sessionUser = this.session.get('currentUser');
      let defaults = getAnonDefaults();
      let avatar = sessionUser.photoURL || defaults.avatar;
      let username = sessionUser.displayName || defaults.username;
      return this.store.createRecord('user', {
        uid: sessionUser.uid,
        username: username,
        avatar: avatar
      });
    },

    openSession(provider) {
      return Ember.get(this, 'session').open('firebase', {
        provider
      }).then(() => {
        Ember.debug("logged in with ".concat(provider));
      }).catch(er => {
        Ember.debug(er);
      });
    },

    usernameFromEmail(email) {
      return email.split('@')[0].replace(/\W/g, '');
    },

    async saveAuthenticatedUser(userRecord) {
      const authProviderUser = await this.session.get('currentUser');
      userRecord.setProperties({
        username: this.usernameFromEmail(authProviderUser.email),
        email: authProviderUser.email,
        uid: authProviderUser.uid,
        avatar: authProviderUser.photoURL
      });
      await userRecord.save();
      this.session.set('currentUserModel', userRecord);
    },

    actions: {
      async login() {
        const userRecord = this.session.get('currentUserModel');

        if (this.isAnonymous) {
          await this.session.close();
        }

        await this.openSession('google');
        this.saveAuthenticatedUser(userRecord);
      },

      async logout() {
        const userRecord = this.session.get('currentUserModel');

        if (userRecord && userRecord.online) {
          Ember.set(this, 'session.currentUserModel.online', false);
          await userRecord.save();
        }

        await this.session.close();
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});