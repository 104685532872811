define("euclidean-cracked/templates/components/ui-sequence-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3pP/OtL0",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[8,\"section\"],[13,1],[9],[0,\"\\n  \"],[14,2],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex flex-column justify-between items-start\"],[9],[0,\"\\n    \"],[5,\"ui-dial\",[],[[\"@max\",\"@step\",\"@onChangeValue\",\"@value\"],[\"16\",\"1\",[27,\"action\",[[22,0,[]],\"updateSequenceParam\",\"hits\"],null],[21,\"hits\"]]],{\"statements\":[[0,\"\\n    \"],[7,\"dl\"],[11,\"class\",\"flex\"],[9],[0,\"\\n      \"],[7,\"dt\"],[11,\"class\",\"fw7\"],[9],[0,\"hits:\"],[10],[0,\"\\n      \"],[7,\"dd\"],[11,\"class\",\"ml1\"],[9],[1,[21,\"hits\"],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"ui-dial\",[],[[\"@max\",\"@step\",\"@onChangeValue\",\"@value\"],[\"16\",\"1\",[27,\"action\",[[22,0,[]],\"updateSequenceParam\",\"steps\"],null],[21,\"steps\"]]],{\"statements\":[[0,\"\\n    \"],[7,\"dl\"],[11,\"class\",\"flex\"],[9],[0,\"\\n      \"],[7,\"dt\"],[11,\"class\",\"fw7\"],[9],[0,\"steps:\"],[10],[0,\"\\n      \"],[7,\"dd\"],[11,\"class\",\"ml1\"],[9],[1,[21,\"steps\"],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"ui-dial\",[],[[\"@max\",\"@step\",\"@onChangeValue\",\"@value\"],[\"16\",\"1\",[27,\"action\",[[22,0,[]],\"updateSequenceParam\",\"offset\"],null],[21,\"offset\"]]],{\"statements\":[[0,\"\\n    \"],[7,\"dl\"],[11,\"class\",\"flex\"],[9],[0,\"\\n      \"],[7,\"dt\"],[11,\"class\",\"fw7\"],[9],[0,\"offset:\"],[10],[0,\"\\n      \"],[7,\"dd\"],[11,\"class\",\"ml1\"],[9],[1,[21,\"offset\"],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "euclidean-cracked/templates/components/ui-sequence-controls.hbs"
    }
  });

  _exports.default = _default;
});