define("euclidean-cracked/components/project-active-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['project-active-users'],
    visible: Ember.computed('project.activeUsers.length', 'project.activeUsers.@each.online', {
      get() {
        let online = Ember.get(this, 'project.activeUsers').filter(user => {
          if (user) {
            return Ember.get(user, 'online');
          }
        });
        return Ember.get(online, 'length') > 1;
      }

    })
  });

  _exports.default = _default;
});