define("euclidean-cracked/components/ui-multislider", ["exports", "euclidean-cracked/mixins/nexus-ui", "ember-did-change-attrs"], function (_exports, _nexusUi, _emberDidChangeAttrs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_nexusUi.default, _emberDidChangeAttrs.default, {
    classNames: ['ui-multislider'],
    tagName: 'span',

    init() {
      this._super(...arguments);

      this.setProperties({
        sliderColor: '#52ebff',
        sliderBgColor: '#f7f7f7'
      });
    },

    didInsertElement() {
      this._super(...arguments);

      this._nexusInit();

      this._styleInit();
    },

    ElementName: 'Multislider',
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    didChangeAttrsConfig: {
      attrs: ['size', 'step', 'values', 'numberOfSliders', 'min', 'max']
    },

    // eslint-disable-next-line complexity
    didChangeAttrs(changes) {
      this._super(...arguments);

      if (changes.size || changes.numberOfSliders || changes.min || changes.max) {
        this._nexusInit();

        this._styleInit();
      }

      if (changes.min || changes.max || changes.values) {
        this.NexusElement.setAllSliders(this.values);
      }

      if (changes.step) {
        this._styleOnStep();
      }
    },

    afterInitNexus(NexusElement) {
      NexusElement.colorize('accent', '#52ebff');
      NexusElement.colorize('fill', '#075f68');
    },

    ElementOptions: Ember.computed('max', 'step', 'values', 'size', 'numberOfSliders', {
      // eslint-disable-next-line complexity
      get() {
        let values = this.values;
        values = values.slice(0, this.numberOfSliders);
        return {
          'size': this.size || [400, 100],
          'min': this.min,
          'max': this.max,
          'candycane': 0,
          'numberOfSliders': this.numberOfSliders || 4,
          'step': this.step || 0,
          'values': values
        };
      }

    }),

    _styleInit() {
      // lookup rect elements by color match(not ideal)
      let fgColor = this.sliderColor; // FIXME terible selector for the nexus ui right here. 

      let $rectElements = this.$("#".concat(this.nexusId)).find("rect[fill='".concat(fgColor, "'][height=120]"));
      Ember.set(this, '$rectElements', $rectElements);
      this.applyStyle();
    },

    _styleOnStep() {
      let $rectElements = this.$rectElements;

      if ($rectElements && this.stepIndex) {
        this.applyStyle();
        let rectIndex = this.stepIndex - 1; // hack to avoid off by one error in current step display

        if (rectIndex === this.sequence.length - 2) {
          rectIndex = rectIndex + 1;
        }

        this.$($rectElements[rectIndex]).attr('stroke', 'red');
      }
    },

    applyStyle() {
      let $rectElements = this.$rectElements;
      let sequence = this.sequence;
      let bgColor = this.sliderBgColor;
      let fgColor = this.sliderColor;
      $rectElements.each(function (idx) {
        let stroke = "#000";
        let fill = sequence[idx] ? fgColor : bgColor;
        Ember.$(this).attr('stroke', stroke);
        Ember.$(this).attr('fill', fill);
      });
    }

  });

  _exports.default = _default;
});