define("euclidean-cracked/helpers/filename-ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filenameUi = filenameUi;
  _exports.default = void 0;

  function filenameUi(params
  /*, hash*/
  ) {
    return params[0].split('.')[0];
  }

  var _default = Ember.Helper.helper(filenameUi);

  _exports.default = _default;
});