define("euclidean-cracked/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    session: Ember.inject.service(),
    uid: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    avatar: _emberData.default.attr('string'),
    // avatar: computed('avatar', {
    //   get() {
    //     let sessionPhoto = this.get('session.currentUser.photoURL');
    //     let userModelAvatar = this.get('session.currentUserModel.avatar');
    //     return userModelAvatar || sessionPhoto;
    //   }
    // }),
    projects: _emberData.default.hasMany('project', {
      inverse: 'creator'
    }),
    comments: _emberData.default.hasMany('comment'),
    online: _emberData.default.attr('boolean'),
    activeProject: _emberData.default.belongsTo('project')
  });

  _exports.default = _default;
});