define("euclidean-cracked/app", ["exports", "euclidean-cracked/resolver", "ember-load-initializers", "euclidean-cracked/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  Array.prototype.rotate = function () {
    // save references to array functions to make lookup faster
    var push = Array.prototype.push,
        splice = Array.prototype.splice;
    return function (count) {
      var len = this.length >>> 0; // convert to uint

      count = count >> 0; // convert to int
      // convert count to value in range [0, len)

      count = (count % len + len) % len; // use splice.call() instead of this.splice() to make function generic

      push.apply(this, splice.call(this, 0, count));
      return this;
    };
  }();

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});