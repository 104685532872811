define("euclidean-cracked/templates/user/creator/projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hvMg3hsz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[11,\"class\",\"white ml3\"],[9],[1,[23,[\"model\",\"creator\",\"username\"]],false],[0,\"'s projects:\"],[10],[0,\"\\n\"],[1,[27,\"project-list\",null,[[\"projects\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "euclidean-cracked/templates/user/creator/projects.hbs"
    }
  });

  _exports.default = _default;
});