define("euclidean-cracked/templates/components/project-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nVdtBqtu",
    "block": "{\"symbols\":[\"project\"],\"statements\":[[4,\"if\",[[23,[\"currentProject\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"audio-project-sequencer\",null,[[\"project\",\"readOnly\"],[[23,[\"currentProject\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"flex\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"projects\"]]],null,{\"statements\":[[0,\"    \"],[5,\"index-project-item\",[],[[\"@project\"],[[22,1,[]]]],{\"statements\":[[0,\"\\n      \"],[1,[27,\"mdc-card-action-icon\",[\"play_arrow\"],[[\"title\",\"click\"],[\"Play\",[27,\"action\",[[22,0,[]],\"setCurrentProject\",[22,1,[]]],null]]]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"p\"],[11,\"class\",\"white ml3\"],[9],[0,\" There are no projects...\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "euclidean-cracked/templates/components/project-list.hbs"
    }
  });

  _exports.default = _default;
});