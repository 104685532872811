define("euclidean-cracked/services/mdc-textfield-configurator", ["exports", "ember-cli-mdc-textfield/services/mdc-textfield-configurator"], function (_exports, _mdcTextfieldConfigurator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _mdcTextfieldConfigurator.default;
    }
  });
});