define("euclidean-cracked/components/mdc-linear-progress--buffer", ["exports", "ember-cli-mdc-linear-progress/components/mdc-linear-progress--buffer"], function (_exports, _mdcLinearProgressBuffer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _mdcLinearProgressBuffer.default;
    }
  });
});