define("euclidean-cracked/serializers/custom-function", ["exports", "emberfire/serializers/firebase"], function (_exports, _firebase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // tood: is checking for isSafe in the client actually safe?
  // can it be ensured that the incoming code will never get executed?
  // firebase rules 
  // ".validate": "null || data.parent().child('isSafe').val() == true"
  var _default = _firebase.default.extend({
    serializeAttribute(snapshot, json, key, attribute) {
      // do not serialize readOnly properties in payload
      // firebase will throw permission denied
      if (attribute.options && attribute.options.readOnly) {
        return;
      }

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});