define("euclidean-cracked/utils/clean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = clean;

  function clean(title) {
    title = title.toLowerCase();
    title = title.replace(/ /g, '-');
    return title.replace(/[^a-zA-Z0-9-_]/g, '');
  }
});