define("euclidean-cracked/components/audio-sampler-track", ["exports", "ember-did-change-attrs"], function (_exports, _emberDidChangeAttrs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* This component updates the cracked audio nodes as track model properties are passed in through the template */
  var _default = Ember.Component.extend(_emberDidChangeAttrs.default, {
    tagName: '',
    // only re-initialize if necessary
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    didChangeAttrsConfig: {
      attrs: ['filepath', 'sequence', 'gain']
    },

    didInsertElement() {
      this._super(...arguments);

      this.track.initializeSampler.perform();
    },

    // eslint-disable-next-line complexity
    didChangeAttrs(changes) {
      this._super(...arguments);

      if (changes) {
        if (changes.sequence && Object.keys(changes).length === 1) {
          this.track.bindTrackSampler();
        } else if (changes.gain) {
          __(this.gainSelector).attr({
            gain: changes.gain.current
          });
        } else {
          this.track.initializeSampler.perform();
        }
      }
    }

  });

  _exports.default = _default;
});