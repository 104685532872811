define("euclidean-cracked/components/ui-dial", ["exports", "euclidean-cracked/mixins/nexus-ui"], function (_exports, _nexusUi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_nexusUi.default, {
    didInsertElement() {
      this._super(...arguments);

      this._nexusInit();
    },

    classNames: 'flex mb2',
    ElementName: 'Dial',
    ElementOptions: Ember.computed('max', 'step', 'value', {
      get() {
        return {
          'size': this.size || [50, 50],
          'interaction': 'vertical',
          // "radial", "vertical", or "horizontal"
          'mode': 'relative',
          // "absolute" or "relative"
          'min': 0,
          'max': this.max || 1,
          'step': this.step || 0,
          'value': this.value
        };
      }

    }),

    afterInitNexus(NexusElement) {
      NexusElement.colorize('accent', '#000000');
      NexusElement.colorize('fill', '#ffffff');
    }

  });

  _exports.default = _default;
});