define("euclidean-cracked/utils/example-onstep-functions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const tutorial = [{
    name: 'sequencer variables',
    code: "\n/*\n  This script gets called as a callback function on \n  every beat of the current track. \n\n  The following step sequencer variables are available to your script\n    data  :  the value (0 or 1) of the current step\n    index :  the position of the current step\n    array :  the contents of the step sequencer as an array\n\n    1 press play, \n    2 open your debug console \n    3 click Euclidean rhythm and notice how these values update\n      on every step of the sequener\n*/\n\nconsole.log('data', data);\nconsole.log('index', index);\nconsole.log('array', array);\n"
  }];
  const filters = [{
    name: 'filter sweep',
    code: "\n/*\n  First select the lowpass filter example in the SETUP function\n\n  Use the cracked library's __.scale() to convert the current step index\n  into a frequency value for the lowpass filter\n*/\n// only run if there is a beat on this step of the sequencer\nif (data) {\n  var inMin = 0;\n  var inMax = array.length;\n  var outMin = 200;\n  var outMax = 2000;\n  var type = 'logarithmic';\n\n  var f = __.scale(index, inMin, inMax, outMin, outMax, type);\n  __('lowpass').attr({ frequency: f, q: 30 });\n}\n"
  }];
  const sampler = [{
    name: 'pitch: chromatic scale',
    code: "\n/*\n  This script will multiply the sample playback speed by intervalic ratios to tune a sample\n  to the notes of a chromatic scale. Try a cowbell or other pitched drum for best effect.\n\n  In the euclidean rhythm menu, set both the hits and steps\n  to 12.\n*/\n\nvar semitoneRatios = [1/1, 16/15, 9/8, 6/5, 5/4, 4/3, 3/2, 8/5, 5/3, 16/9, 15/8, 2/1];\nvar speed = semitoneRatios[index];\n__(this.sampler).attr({speed: speed});\n\n/*\n\n  The variable \"index\" is the current step in the sequence.\n  \n  1:1   unison\n  16:15 minor seconrd\n  9:8   major second\n  6:5   minor third\n  5:4   major third\n  4:3   fourth\n  3:2   fifth\n  8:5   minor sixth\n  5:3   major sixth\n  5:3   minor seventh\n  16:9  major seventh\n  15:8  octave\n*/\n"
  }, {
    name: 'reverse',
    code: "\nvar reverseSpeed = index % 2 ? -1 : 1; // reverseSpeed is -1 on every other step. \n__(this.sampler).attr({speed: reverseSpeed});\n"
  }];
  var _default = [{
    sectionName: 'tutorial',
    examples: tutorial
  }, {
    sectionName: 'sampler',
    examples: sampler
  }, {
    sectionName: 'filters',
    examples: filters
  }];
  _exports.default = _default;
});