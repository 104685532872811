define("euclidean-cracked/components/mdc-card-action-icons", ["exports", "ember-cli-mdc-card/components/mdc-card-action-icons"], function (_exports, _mdcCardActionIcons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _mdcCardActionIcons.default;
    }
  });
});